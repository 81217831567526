import * as React from 'react';
import {
  Box,
  Button,
  ButtonProps,
  Popover,
  PopoverContent,
  PopoverProps,
  PopoverTrigger,
  Textarea,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';

import { MessageCircle } from 'lucide-react';

import { useUser } from '@clerk/nextjs';
import { captureMessage, captureUserFeedback } from '@sentry/nextjs';

export const FeedbackButton = ({
  buttonProps,
  popoverProps,
}: {
  buttonProps?: ButtonProps;
  popoverProps?: PopoverProps;
}) => {
  const toast = useToast();

  const { user } = useUser();

  const { onOpen, onClose, isOpen } = useDisclosure();
  const [feedback, setFeedback] = React.useState('');

  const inputRef = React.useRef<HTMLTextAreaElement>(null);
  React.useEffect(() => {
    if (isOpen) {
      setTimeout(() => inputRef.current?.focus(), 50);
    }
  }, [isOpen]);

  const onSubmitFeedback = () => {
    captureUserFeedback({
      event_id: captureMessage('User Feedback'),
      name: user?.fullName ?? '',
      email: user?.primaryEmailAddress?.emailAddress,
      comments: feedback,
    });

    setFeedback('');
    onClose();

    toast({
      title: "Thanks! We'll be in touch",
      duration: 3000,
      position: 'bottom',
    });
  };

  return (
    <Popover
      gutter={14}
      onOpen={onOpen}
      onClose={onClose}
      isOpen={isOpen}
      {...popoverProps}
    >
      <PopoverTrigger>
        <Button
          size="sm"
          flex="none"
          color="white"
          bg="yellow.500"
          _hover={{ bg: 'yellow.600' }}
          aria-label="Help and feedback"
          leftIcon={<MessageCircle size="16px" />}
          sx={{
            '.chakra-button__icon': {
              marginRight: [0, 0, 2],
            },
            '.label': {
              display: ['none', 'none', 'inline'],
            },
          }}
          {...buttonProps}
        >
          <span className="label">Help and feedback</span>
        </Button>
      </PopoverTrigger>
      <PopoverContent
        transitionDuration="50ms"
        bg="gray.900"
        boxShadow="dark-lg"
        borderColor="gray.700"
        color="gray.100"
        p="3"
      >
        <Box fontWeight="semibold" fontSize="sm" mb="3">
          Help and feedback
        </Box>
        <Textarea
          mb="3"
          autoFocus
          ref={inputRef}
          placeholder="We would love to hear from you! Please provide any issues or feedback."
          value={feedback}
          rows={6}
          fontSize="sm"
          onChange={(e) => setFeedback(e.target.value)}
        />
        <Button
          size="sm"
          isDisabled={!feedback}
          onClick={onSubmitFeedback}
          colorScheme="blue"
        >
          Send
        </Button>
      </PopoverContent>
    </Popover>
  );
};
