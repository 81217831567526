import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { ClerkProvider } from '@clerk/nextjs';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Analytics as VercelAnalytics } from '@vercel/analytics/react';
import type { NextPage } from 'next';
import { DefaultSeo } from 'next-seo';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { ErrorBoundary } from '@sentry/nextjs';

import { ErrorLayout } from '../components/ErrorLayout';
import { GoogleAnalytics } from '../lib/googleAnalytics';
import '../styles/globals.css';
import { ReactElement, ReactNode, useEffect } from 'react';
import env from '../env/frontend';

if (typeof window !== 'undefined' && env.NEXT_PUBLIC_ENV === 'production') {
  posthog.init(env.NEXT_PUBLIC_POSTHOG_KEY, {
    api_host: env.NEXT_PUBLIC_POSTHOG_HOST,
    capture_pageview: false,
    session_recording: {
      maskAllInputs: true,
    },
  });
}

const theme = extendTheme({
  colors: {
    brandRed: {
      500: '#F5385A',
      600: '#CD2D4A',
    },
    brandBlue: {
      500: '#308DE2',
    },
  },
});

const queryClient = new QueryClient();

const ErrorFallback = () => {
  return (
    <ErrorLayout>
      There was a problem rendering Magnet. Please refresh the page.
    </ErrorLayout>
  );
};

function App({ Component, pageProps }: AppPropsWithLayout) {
  const router = useRouter();
  useEffect(() => {
    if (env.NEXT_PUBLIC_ENV !== 'production') {
      return;
    }

    const handleRouteChange = () => posthog.capture('$pageview');
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  const getLayout = Component.getLayout ?? ((page) => page);
  let app = (
    <>
      <DefaultSeo
        openGraph={{
          type: 'website',
          locale: 'en_US',
          url: 'https://www.magnet.run/',
          siteName: 'Magnet',
          description: 'An expert AI coding assistant',
          images: [
            {
              url: 'https://magnet.run/og-image.png',
              width: 1200,
              height: 630,
              alt: 'Magnet',
            },
          ],
        }}
      />
      <Head>
        <title>Expert AI coding assistant - Magnet</title>
        <meta
          name="description"
          content="Magnet is an expert AI coding assistant"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" type="image/x-icon" href="/favicon.ico" />
      </Head>
      <ChakraProvider
        theme={theme}
        toastOptions={{
          defaultOptions: {
            position: 'top',
            variant: 'subtle',
            isClosable: true,
          },
        }}
      >
        <ClerkProvider
          localization={{
            createOrganization: {
              title: 'Create organization',
            },
            signUp: {
              start: {
                title: 'Create an account for free',
              },
            },
            organizationSwitcher: {
              action__manageOrganization: 'Manage organization',
              action__createOrganization: 'Create organization',
            },
          }}
          {...pageProps}
        >
          <ErrorBoundary fallback={ErrorFallback}>
            <QueryClientProvider client={queryClient}>
              <ReactQueryDevtools
                initialIsOpen={false}
                toggleButtonProps={{
                  style: { width: 20, height: 20, marginBottom: 20 },
                }}
              />
              {getLayout(<Component {...pageProps} />, pageProps)}
              <GoogleAnalytics />
              <VercelAnalytics />
            </QueryClientProvider>
          </ErrorBoundary>
        </ClerkProvider>
      </ChakraProvider>
    </>
  );

  if (env.NEXT_PUBLIC_ENV === 'production') {
    app = <PostHogProvider client={posthog}>{app}</PostHogProvider>;
  }
  return app;
}

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement, pageProps: any) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

export default App;
