import { Button, Flex, Text } from '@chakra-ui/react';
import { FeedbackButton } from './FeedbackButton';
import { useUser } from '@clerk/nextjs';

export const ErrorLayout = ({ children }: React.PropsWithChildren<object>) => {
  const { user } = useUser();

  return (
    <Flex
      position="fixed"
      inset="0"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      gap="3"
    >
      <Text
        color="red.200"
        fontWeight="medium"
        fontSize="lg"
        textAlign="center"
        maxW="400px"
        mb={10}
      >
        {children}
      </Text>
      <FeedbackButton />
      {user?.id && (
        <Button colorScheme="whiteAlpha" size="sm" as="a" href="/code">
          Go home
        </Button>
      )}
    </Flex>
  );
};
