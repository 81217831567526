import { envsafe, str } from 'envsafe';

const env = envsafe(
  {
    NEXT_PUBLIC_ENV: str({ choices: ['development', 'production'] }),
    NEXT_PUBLIC_POSTHOG_KEY: str({ allowEmpty: true, default: '' }),
    NEXT_PUBLIC_POSTHOG_HOST: str({ allowEmpty: true, default: '' }),
  },
  // Nextjs does some dynamic magic on the frontend so
  // env vars have to be referenced explicitly
  {
    env: {
      NEXT_PUBLIC_ENV: process.env.NEXT_PUBLIC_ENV,
      NEXT_PUBLIC_POSTHOG_KEY: process.env.NEXT_PUBLIC_POSTHOG_KEY,
      NEXT_PUBLIC_POSTHOG_HOST: process.env.NEXT_PUBLIC_POSTHOG_HOST,
    },
  }
);

export default env;
